
import { defineComponent, ref } from 'vue'
import { useUserPreferences } from '@dview/logic'
import { useRouter } from 'vue-router'

declare const eruda: any;
let erudaConsoleLoaded = false

export default defineComponent({
    setup() {
        const { setUserOnboarded } = useUserPreferences()
        const router = useRouter()
        const mobileConsoleEnabled = ref(erudaConsoleLoaded)

        function enableMobileConsole() {
            if (!erudaConsoleLoaded) {
                initializeErudaConsole()
                erudaConsoleLoaded = true
            }

            mobileConsoleEnabled.value = true
        }

        function resetUserOnboarding() {
            setUserOnboarded(false)
            router.push({
                path: '/deviations',
            })
        }

        function initializeErudaConsole() {
            const script = document.createElement('script');
            
            script.onload = function () {
                eruda.init()
            }

            script.src = 'https://cdnjs.cloudflare.com/ajax/libs/eruda/2.4.1/eruda.min.js'
            document.head.appendChild(script);
        }

        function sendNotification() {
            router.push({
                path: '/developer/notification',
            })
        }

        function errorPage() {
            router.push({
                path: '/error',
            })
        }

        return {
            mobileConsoleEnabled,
            enableMobileConsole,
            resetUserOnboarding,
            sendNotification,
            errorPage,
        }
    },
})
